<template>
  <div class="main">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <Items msg="Welcome to Your Vue.js App" v-if="userIsAuthenticated" />
    <Public v-else />
  </div>
</template>

<script>
// @ is an alias to /src
import Items from '@/components/Items.vue'
import Public from '@/components/Public.vue'

export default {
  name: 'home',
  components: {
    Items, 
    Public
  },
  computed: {
    userIsAuthenticated () {
      return this.$store.getters.user !== null && this.$store.getters.user !== undefined
    },
    user () {
      return this.$store.getters.user
    }
  }
}
</script>

<style lang="scss" scoped>


</style>
