import { db } from '@/main'
import moment from 'moment'

export default {
  state: {
    items: null
  },
  getters: { 
    getItems: state => {
      return state.items
    }
  },
  mutations: {
    setItems: (state, userID) => {
      let userItems = []
      
      db.collection('items').doc(userID).collection('userItems').orderBy('exp_date').onSnapshot((snapshot) => {
        userItems = []
        snapshot.forEach((doc) => {

          let currentDate = moment().set('second', Date.now()/1000);
          let itemDate = moment().set('second', doc.data().exp_date.seconds)
          let diffDays = itemDate.diff(currentDate, 'days')

          userItems.push({ id: doc.id, title: doc.data().title, exp_date: diffDays })

        })
        state.items = userItems

      })
    }
  },
  actions: {
    setItems: context => {
       context.commit('setItems', context.rootGetters.user.id)
    }
  }
}