<template>
  <div id="app">
    <header class="app-header">   
      <!-- <router-link to="/" class="link-home">time-sensitive.info</router-link>   -->
      <div id="nav">
        <router-link to="/info">info</router-link> 
        <router-link to="/" v-if="user">{{ user.email }}</router-link> 
        <router-link v-for="item in menuItems"
            :key="item.title"
            :to="item.link">
          {{ item.title }}
        </router-link>
        
        <a href="#" v-if="userIsAuthenticated"
            @click="onLogout">
            logout
        </a>
      </div>
    </header>

    <router-view/>
  </div>
</template>

<script>
  export default {
    computed: {
      menuItems () {
        let menuItems = [
          {icon: 'face', title: 'sign up', link: '/signup'},
          {icon: 'lock_open', title: 'sign in', link: '/signin'}
        ]
        if (this.userIsAuthenticated) {
          menuItems = [
            // {icon: 'person', title: 'profile', link: '/profile'},
            // {icon: 'person', title: 'time-sensitive', link: '/'}
          ]
        }
        return menuItems
      },
      userIsAuthenticated () {
        return this.$store.getters.user !== null && this.$store.getters.user !== undefined
      },
      user () {
        return this.$store.getters.user
      }
    },
    methods: {
      onLogout () {
        this.$store.dispatch('logout')
        this.$router.push('/')
      }
    }
  }
</script>

<style lang="scss">
// @import url(./assets/main.scss);

* {
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
}

html, body {
  background-color: #A9A9A9;
  margin:0px;
  padding:0px;
  color: #626262;
  font-weight: 400;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  height: 100%;
}
#app {
  background-color: #A9A9A9;
  font-family: "Helvetica Now Display", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  border-radius: 54px 54px 0px 0px;
  padding:24px;
}
button {
  font-family: "Helvetica Now Display", Arial, sans-serif;
  display: inline-flex;
  margin: 8px;
  padding:8px 24px;
  border-radius: 24px;
  background-color:#00daa9;
  border: none;
  color: darken(#00daa9, 25%);
  text-align: center;
  justify-content: center;
  border:none;
  font-weight: 500;
  font-size: 1rem;

  &:hover {
    background-color: darken(#00daa9, 2%);
    box-shadow: 1px 1px 12px rgba(0,0,0,0.15);
    cursor: pointer;
  }
}
a {
  text-decoration: none;
}
h1, h2, h3 {
  font-weight: 400;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #333;
}

.app-header {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top:0px;
  z-index: 5;
  width: 100%;

  a {
    color: #696969;
  }
  a:hover  {
    color: #2b2b2b;
  }
}
.link-home {
  margin-left: 32px;
}

input {
  font-size: 1rem;
  border: 1px solid #999;
  padding: 16px 16px;
  border-radius: 8px;
  margin: 6px 0px;
}

.main {
  display: flex;
  align-items: center;
  // padding: 0px 128px;
}

#nav a {
  margin:0px 12px;
}

@media (min-width: 768px) {

}
@media (min-width: 1024px) {

}

</style>