<template>
  <div class="lp">
    <h1 class="main-title">time-sensitive</h1>
    <p class="subtitle-title">Stay on top of time.</p>

    <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'public',
}
</script>

<style lang="scss" scoped>

* {
  margin: 0;
  padding: 0;
  // box-sizing: border-box !important;
}

html, body {
  height: 100%;
}

body {
  display: table;
  width: 100%;
  height: 100%;
  background-color: #171717;
  color: #000;
  line-height: 1.6;
  position: relative;
  font-family: sans-serif;
  overflow: hidden;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 100%;
}
.lines .line {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  right: 0;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 15vh;
  top: 0;
  right: -50%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  -webkit-animation: run 7s 0s infinite;
          animation: run 7s 0s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
          animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(1) {
  margin-top: -25%;
}
.lines .line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.lines .line:nth-child(3) {
  margin-top: 25%;
}
.lines .line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

@-webkit-keyframes run {
  0% {
    right: -50%;
  }
  100% {
    right: 110%;
  }
}

@keyframes run {
  0% {
    right: -50%;
  }
  100% {
    right: 110%;
  }
}

@media (min-width: 768px) {
  .lp {
    width: 420px;
  }
  .main-title {
    text-align: left;
    margin: 0px;
    color: #fff;
    font-size: 7rem;
    font-weight: 300;
    line-height: 5rem;
    margin-bottom: 12px;
  }
  .subtitle-title {
    text-align: left;
    align-items: unset;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 400;
    margin: 0px;
  }
}
@media (min-width: 1024px) {
  
}


</style>