import Vue from 'vue'
import App from './App.vue'
import firebase from 'firebase'
import router from './router/index.js'
import store from './store/index.js'
// const AlertCmp = () => import('./components/Shared/Alert.vue')


Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: 'AIzaSyCpnkuJt2beIc3Zrp-XORMcIFTypTxU1pI',
  authDomain: 'strange-bay-151601.firebaseapp.com',
  databaseURL: 'https://strange-bay-151601.firebaseio.com',
  projectId: 'strange-bay-151601',
  storageBucket: 'strange-bay-151601.appspot.com',
  messagingSenderId: '287402683941',
  appId: '1:287402683941:web:386c0aaeeb3dc454'
};

firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()

new Vue({
  store,
  router,
  render: h => h(App),
  created () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('autoSignIn', user)
      } 
    })
  }
}).$mount('#app')
