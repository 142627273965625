import Vue from 'vue'
import Vuex from 'vuex'
import user from './user/'
import shared from './shared'
import items from './items'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: user,
    shared: shared, 
    items: items
  },
  state: {
    // items: null, 
    // userID: 'qlvdlhNmRiWNf32BrdtpyaBLYE62'
  },
  getters: { 
    // getItems: state => {
    //   return state.items
    // }
  },
  // computed: {
  //   user () {
  //     return this.$store.getters.user
  //   }
  // },
  mutations: {
    // setItems: (state, user) => {
    //   let userItems = []
    //   console.log(user)

    //   db.collection('items').doc(user).collection('userItems').orderBy('exp_date').onSnapshot((snapshot) => {
    //     userItems = []
    //     snapshot.forEach((doc) => {
    //       userItems.push({ id: doc.id, title: doc.data().title, exp_date: doc.data().exp_date })
    //     })
    //     state.items = userItems
    //   })
    // }
  },
  actions: {
    // setItems: context => {
    //   context.commit('setItems', context.state.userID)
    // }
  }
})
