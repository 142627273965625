<template>
  <div class="user-home">
    <div v-if="errors !== ''" id="errors">{{ errors }}</div>

    <div v-if="this.$store.getters.getItems && this.$store.getters.getItems.length > 0" class="items">
 
     <div v-for="item in this.$store.getters.getItems" :key="item.id" class="item" v-if="item.exp_date >= 0">
       <span class="time-item">{{ item.title }}</span>
       <span class="time-left" v-if="user"> {{ item.exp_date }}</span>
       <a href="#" @click="deleteItem(item.id)" class="time-item-delete">
          <svg class="delete" width="30px" height="29px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="web-app" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
                  <g id="logged-in-delete" transform="translate(-1078.000000, -174.000000)" stroke="#FFFFFF" stroke-width="2">
                      <g id="Group-3" transform="translate(1079.000000, 174.000000)">
                          <g id="Group-4">
                              <path d="M14,-3.5 L14,31.9804688" id="Line" transform="translate(14.000000, 14.000000) rotate(-45.000000) translate(-14.000000, -14.000000) "></path>
                              <path d="M14,-3.5 L14,31.9804688" id="Line" transform="translate(14.000000, 14.000000) rotate(-315.000000) translate(-14.000000, -14.000000) "></path>
                          </g>
                      </g>
                  </g>
              </g>
          </svg>
        </a>
     </div>
    </div>
    <div v-else class="items">
      No items
    </div>

    <div v-if="showModal" class="modal-wrapper">
      <div class="modal">
        <div class="modal-header">
          <h2>Add item</h2>
          <button class="button-modal-close" @click="addItemModal">
            <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="web-app" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g id="logged-in-upgrade" transform="translate(-980.000000, -326.000000)" stroke="#6A6A6A" stroke-width="2">
                        <g id="Group-6" transform="translate(981.000000, 327.000000)">
                            <path d="M9.5,-2.65277778 L9.5,21.9864366" id="Line" transform="translate(9.500000, 9.500000) rotate(-45.000000) translate(-9.500000, -9.500000) "></path>
                            <path d="M9.5,-2.65277778 L9.5,21.9864366" id="Line" transform="translate(9.500000, 9.500000) rotate(-315.000000) translate(-9.500000, -9.500000) "></path>
                        </g>
                    </g>
                </g>
            </svg>
          </button>
        </div>
        <span class="errors"></span>

        <div class="modal-body">
          <div class="columns">
            <div class="form-field column">
              <label>item name</label>
              <input v-model="myTodo" class="add-item-field" placeholder="i.e. Passport"/>
            </div>
            <div class="form-field column">
              <label>expiration date</label>
              <datepicker v-model='exp_date' name="exp-date-picker" placeholder="select expiration date"></datepicker>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="addToDo" class="button">
            add
          </button>
        </div>
      </div>
    </div>

    <button @click="addItemModal" class="add-item-button" v-bind:class="{ active: showModal }">
      <span class="cross">
        <span class="line-v"></span>
        <span class="line-h"></span>
      </span>
    </button>

  </div>
</template>

<script>
import { db } from '@/main'
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'Items',
  components: {
    Datepicker
  },
  beforeCreate: function (state) {
    this.$store.dispatch('setItems')
  },
  data: function () {
    return {
      myTodo: '', 
      errors: '',
      showModal: false,
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
  },
  methods: {
    formatNumber(value) {
        let val = (value/1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    addItemModal: function (state) {
      if (this.showModal == false) {
        this.showModal = true
      } else {
        this.showModal = false
      }
    },
    addToDo: function () {        
      this.errors = ''
      if (this.myTodo !== '', this.date !== '') {

        db.collection('items').doc(this.user.id).collection('userItems').add({
          title: this.myTodo,
          exp_date: this.exp_date,
          created_at: Date.now()
        }).then((response) => {
          if (response) {
            this.myTodo = ''
          }
          this.addItemModal()
        }).catch((error) => {
          this.errors = error
        })
      } else {
        this.errors = 'Please enter some text'
      }
    },
    deleteItem: function (id) {
     if (id) {
       db.collection('items').doc(this.user.id).collection('userItems').doc(id).delete().then(function() {
           alert('Document successfully deleted!');
       }).catch(function(error) {
           this.errors = error
       })
     } else {
       this.error = 'Invalid ID'
     }
   }
  }
}
</script>

<style lang="scss" scoped>
$timing: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$duration: 0.4s;

.user-home {
  width: 100%;
}
.time-item-delete {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -30px;
  height: 60px;
  width: 60px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.0);
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.item {
  // display: grid;
  // align-items: baseline;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  // grid-gap: 24px;
  // margin: 6px;
  // padding: 24px;
  // color:#fff;
  // position: relative;
  // border-radius: 12px;
  display: flex;
  color: #fff;
  position: relative;

  transition: background-color 0.25s ease-in-out;
  align-items: center;
  padding: 0px 24px;
  justify-content: space-between;

  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: #a0a0a0;
    transition: background-color 0.25s ease-in-out;
  }
}
.item:hover .time-item-delete {
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.2);
    transition: opacity 0.25s ease-in-out;
  }
}

.time-left {
  grid-column-start: 2;
  display: flex;
  align-content: baseline;
  justify-content: flex-end;
  font-size: 74px;
  font-weight: 400;
}
.time-item {
  display: flex;
  align-content: baseline;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

.modal-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top:0px;
  left:0px;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.15);
}
.modal {
  z-index: 10;
  width: auto;
  padding:32px;
  min-width: 320px;
  border-radius: 12px;
  background-color: #fff;
}
.modal-header, 
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-footer {
  justify-content: flex-end;
}
.button-modal-close {
  padding: 12px;
  background-color: transparent;
  opacity: 0.7;
  &:hover  {
    opacity: 1;
  }
}

.columns {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.column {
  margin: 0px 12px;
}
.form-field {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

/* Time items */

.time-left, 
.time-item {
  opacity: 0.25;
}

.item:nth-child(6) {
  .time-left, .time-item {
      opacity: .35;
  }
}

.item:nth-child(5) {
  .time-left, .time-item {
      opacity: .45;
  }
}

.item:nth-child(4) {
  .time-left, .time-item {
      opacity: .55;
  }
}

.item:nth-child(3) {
  .time-left, .time-item {
      opacity: .65;
  }
}

.item:nth-child(2) {
  .time-left, .time-item {
      opacity: .75;
  }
}

.item:nth-child(1) {
  .time-left, .time-item {
      opacity: 1;
  }
}

.add-item-button {
  z-index: 20;
  transition: all $duration $timing;
  background-color: #50E3C2;
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  margin-bottom:46px;
  bottom: 24px;
  right: 24px;
  color: #555;
  box-shadow: 2px 2px 16px rgba(0,0,0,0.2);

  &:hover {
    transition: all $duration $timing;
    cursor: pointer;
    width: 84px;
    height: 84px;
    bottom: 24px;
    right: 24px;
    background-color: #00daa9;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.4);
  }
}
.cross {
  position: relative;
  display: flex;
  .line-v, 
  .line-h {
    transition: all $duration $timing;
    display: flex;
    background-color: #2E967F;
    border-radius: 2px;
  }
  .line-v {
    width: 2px;
    height: 48px;
  }
  .line-h {
    width: 48px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -24px;
  }
}
.add-item-button.active {
  transition: all $duration $timing;
  bottom: -10vh;
  margin-bottom:0px;
  transform: rotate(45deg);
  background-color: #656565;
  display:none;

  .line-v, 
  .line-h {
    transition: all $duration $timing;
    background-color: #fff;
  }
}

@media (max-width: 768px) {
  .columns {
    display: block;
  }
}
@media (min-width: 1024px) {
  .item {
    border-radius: 12px;
    padding: 0px 116px;
  }
  .columns {
    display: block;
  }
}

</style>
